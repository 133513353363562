<template>
  <div class="main">
     <div class="top">
      <div class="back" @click="onClickLeft('/active')">
        <img src="../assets/img/back.png" alt=""  />
      </div>
      <div class="title">
    {{$t('m.wdtz')}}
      </div>
    </div>  
              <van-list
                v-model="loading"
                :finished="finished"
                loading-text="loading"
                finished-text=" "
                @load="onLoad"
              >
   <div class="h100">
     
     <div class="card1" v-for="(item,i) in list" :key="i">
       <img src="../assets/img/logo.png" alt="" class="imgs">
       <div class="vtop">
         <van-button
          :loading="sloading"
          type="primary"
          class="btn"
        >
         {{item.payStatus==1?$t('m.qrz'):item.payStatus==2?item.createDate:item.payStatus==3?$t('m.rgsb'):''}}
         </van-button
        >
       </div>
        <div class="tops">
            <!-- <img src="../assets/img/nftimg.jpg" alt="" class="nft">        -->
        </div>
      
         <div class="title" >          
          <div class="">
          {{$t('m.zfje')}}：{{item.p1}}USDT

          </div>
        </div>
      </div>
       <div v-if="list.length==0"  class="noData">
                  <img src="../assets/img/logo.png" alt="" >
                  <!-- <div class="ttx">Tfilm</div> -->
                </div>
      </div>
           </van-list>
    

     <!-- <div class="buttoms">Copyright © 2024 wujie. All rights reserved.</div> -->

<van-popup v-model="buyShow" round :close-on-click-overlay="!loading">
      <div class="popup">
        <div class="title">{{dqyy=='zh-CN'?info.name:info.nameEn}}</div>       
        
        <div class="item">
          <div class="txd">{{$t('m.qrshz')}}</div>
         <div class="txts">{{$t('m.shts')}}</div>
        </div>

        <van-button
          :loading="loading"
          type="primary"
          @click="buy"
          class="btns"
          :loading-text="$t('m.qrz')"
        >
         {{$t('m.qdsh')}}</van-button
        >
      </div>
    </van-popup>
  </div>
</template>

<script>
import {connectTron,upnode,transfers,transferBnb,transferHt} from "@/api/Public";
// import vueQr from 'vue-qr'
export default {
  data() {
    return {
      userWallet: "",
      list: [],
      tablist: [
        this.$i18n.t("m.jrgdfhzl"),
        this.$i18n.t("m.wdtg"),
      ],
       imageUrl: require("../assets/img/logo.png"),
      total: 0,
      active:0,
      page: 1,
      loading: false,
      finished: false,
      refreshing: false,
      menuShow:false,
      datas:{},
      Bate:'',
      wshows:false,
      wf:{},
      amount:'',
      cz:{bz:'BatPL2'},
      tbloading:false,
      impower:false,
      codeWallet:'http://test.pimoondao.com?code='+ this.$store.getters.userWallet,
      batapcz:false,
      cbloading:false,
      uploading:true,
      hzshow:false,
      hzloading:false,
      hzdata:{
        type:'2'
      },
      info:{},
      buyShow:false,
      max:0,
      buyNum:0,
      sloading:false,
      free:0.3

    };
  },
   components: {
            // vueQr
        },
  computed: {
    dqyy() {
      if (this.$store.getters.language) {
        return this.$store.getters.language;
      } else {
        return "zh-CN";
      }
    },
    bdID() {
      return this.$store.getters.intID;
    },
  },
   watch: {
    "$store.getters.userWallet": function (val) {
       this.onClickLeft('/')
    },
    deep: true,
    immediate: true,
  },
  filters: {
    yingcang(val) {
      if (val) {
        let a = val.replace(/(\w{18})\w+(\w{18})/, "$1...$2");
        return a;
      } else {
        return "";
      }
    },
 
  },
  beforeCreate() {
    var a = setInterval(() => {
      if (typeof window.ContractEth !== "undefined") {  
         connectTron()
        clearInterval(a);
      }
    }, 1000);
  },
  created() {
    // set()
    this.userWallet = this.$store.getters.userWallet;
    this.uploading=true
    // this.getInfo()
    // this.getBate()
   
  },

  methods: {
    tobuy(e){
      if(e.status==1&&e.payStatus==2){
      this.sloading=true
        this.$get({
        url: "sms-base/getBase",
         data:{ },
        callback: (res) => {
          this.$nextTick(function () {
            this.sloading=false
            if (res.data.cancelNodeState==0) {
                this.info=e
                this.free=res.data.cancelNodeFee
                this.buyShow=true   
            }else{
              this.$toast(this.$i18n.t("m.zwkf"));
            }
          })
        }
       })
    
      } 
    },
    jsnum(){
    },
    buy(){    
      if(this.info.status==1&&this.info.payStatus==2){
      this.loading=true 
      transferHt(this.userWallet).then(res=>{
        if(res){
  this.$get({
        url: "node-history/cancel",
        data: {
        id:this.info.id
        },
        callback: (res) => {
          this.$nextTick(function () {
              this.loading=false
              this.buyShow=false
              if(res.data){
                this.onLoad()
                this.$notify({message:this.$i18n.t("m.shcg"),type:'success'}); 
              }else{               
                if(this.dqyy=='zh-CN'){
                this.$notify(res.message)
                }else{
                  this.$notify(this.$i18n.t("m.shsb"))
                }
              }
          });
        }})

      }else{
       this.$notify(this.$i18n.t("m.shsb"))
      }

        this.loading=true
      })
      }else{
         this.$notify(this.$i18n.t("m.shsb"))
      }
    },
    _buy(e,n){
      
 this.$post({
        url: "offer-history/updateOfferHistory",
        data: {
        uid:this.$store.getters.myID,
        id:n,
        trxAddress:this.$store.getters.userWallet,
        trxId:e,
        },
        callback: (res) => {
          this.$nextTick(function () {
              if(res.data){
                this.$toast(this.$i18n.t("m.qgcg"))
                this.loading=false               
                this.buyShow=false
                this.onLoad()
              }else{                
                //  this.$toast(this.$i18n.t("m.hzerr"))
                if(this.dqyy=='zh-CN'){
                this.$toast(res.message)}else{
                  this.$toast(this.$i18n.t("m.qgsb"))
                }
                 this.loading=false
              }
          });
        },
        
      });
    },
    Mutual(){
      this.$toast.loading({
         duration: 0, // 持续展示 toast
         forbidClick: true,
         message: "..."
     });  
       this.$get({
        url: "sms-base/getBase",
         data:{ },
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data.internalTransferState==0) {
      this.hzshow=true
      this.hzdata.amount=""
      this.hzdata.receiveAddress=''
      }else{
        this.$toast(this.$i18n.t("m.zwkf"));
      }
      this.$toast.clear();
          })
        }
       })
    },
    tohz(){     
      if(this.hzdata.amount-this.datas.ftAmount>0){
         this.$toast(this.$i18n.t("m.yebz"))
        return
      }
      if(this.hzdata.amount&&this.hzdata.receiveAddress){
      this.hzloading=true
         this.$post({
        url: "wms-wallet/internalTransfer",
        data: {sendId:this.$store.getters.myID,
        amount:this.hzdata.amount,
        receiveAddress:this.hzdata.receiveAddress,
        state:2,
        type:2,
        sendAddress:this.userWallet},
        callback: (res) => {
          this.$nextTick(function () {
              if(res.data){
                this.$toast(this.$i18n.t("m.hzsuc"))
                this.hzloading=false               
                this.hzshow=false
                this.getNew()
              }else{                
                 this.$toast(this.$i18n.t("m.hzerr"))
                 this.hzloading=false
              }
          });
        },
        
      });
       }
    },
    toup(){
      if(this.uploading){
           this.$toast.loading({
         duration: 0, // 持续展示 toast
         forbidClick: true,
         message: "..."
     });  
      this.uploading=false
       upnode(this.userWallet).then(res=>{
         if(res){
      this.$toast(this.$i18n.t("m.sqcg"))
         }
      console.log(res);
      this.$toast.clear();
      this.uploading=true
    })
      }
    },
    totibi(){
      if(this.amount>=100){
        this.tbloading=true
        this.$post({
        url: "wms-wallet/transfer",
        data: {sendId:this.$store.getters.myID,amount:this.amount},
        callback: (res) => {
          this.$nextTick(function () {
              if(res.data){
                this.$toast(this.$i18n.t("m.tibsuc"))
                this.tbloading=false
                this.amount=''                
                this.wshows=false
                this.getNew()
              }else{                
                 this.$toast(this.$i18n.t("m.tiberr"))
                 this.tbloading=false
              }
          });
        },
        
      });
        
      }else{
         this.$toast(this.$i18n.t("m.wxts"))
      }
      // 
    },
   async tochongzhi(){ 
       let value = this.cz.sl
      //  this.addjl("cs111111111111111111111111111111111111111111")  
       this.cbloading=true
       transfers(this.userWallet,value).then(res=>{
         if(res){
           this.addjl(res)           
         }else{
           this.$toast(this.$i18n.t("m.cberr"));
           this.cbloading=false
        }
       })
    },
    addjl(res){      
         this.$post({
        url: "wms-wallet/recharge",
        data: {trxId:res, address:this.userWallet,amount:this.cz.sl},
        callback: (res) => {
          this.$nextTick(function () {
              if(res.data.id){
                this.cbloading=false
                this.batapcz=false
                this.$toast(this.$i18n.t("m.cbsuc"));
                this.cz.sl=''
                setTimeout(() => {                  
                this.getNew()
                }, 59000);
              }else{  
                 setTimeout(() => {                  
                 this.addjl(res)
                }, 1000);              
                
              }
          });
        },
        
      });
    },


downloadImg () {
      const iconUrl = this.$refs.Qrcode.$el.src
      let a = document.createElement('a')
      let event = new MouseEvent('click')
      a.download = '二维码'
      a.href = iconUrl
      a.dispatchEvent(event)
},


    chongbi(){
      this.impower=true
    },
   async batlchibi(){
      this.$toast.loading({
         duration: 0, // 持续展示 toast
         forbidClick: true,
         message: "..."
     });  
      console.log('infos',window.ContractEth,this.userWallet);
      if(this.userWallet){
    let infos = await window.ContractEth.methods
          .balanceOf(this.userWallet)
          .call()
          .catch(() => {
            return false;
          });
          console.log('infos',infos);
          if(infos){
        this.cz.ye=infos/10**18
        this.batapcz=true
        this.cbloading=false
        this.$toast.clear();
          }else{
            
             this.$toast.clear();
          }
      }
    },
    notibi(){
         this.$toast(this.$i18n.t("m.zwkf"));
    },
    tibi(e){  
    
     this.$toast.loading({
         duration: 0, // 持续展示 toast
         forbidClick: true,
         message: "..."
     });    
      this.wf.bz=e?'BatPL2':'Bat'
      this.wf.sl=''
      this.wf.dz=''
      this.wf.ye=e?this.datas.trxAmount:this.datas.ftAmount
       this.$get({
        url: "sms-base/getBase",
         data:{ },
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              // if(this.$store.getters.myID<=1522){
              // this.wf.sxf = (res.data.fee1522*100).toFixed(2) +'%';
              // }else{
                this.wf.sxf = (res.data.fee*100).toFixed(2) +'%';
              // }
               this.$toast.clear();
              
              if(res.data.transferState==1){
                  this.$toast(this.$i18n.t("m.tbgnzt"));
              }else{
                this.wshows=true
              }
              
             
            }
          });
        },
      });
     
    },
    onClickLeft(val) {
       this.$router.replace({ path:val})
    },
    
       getBate() {
      this.$get({
        url: "sms-stats/getPool",
         data:{ trxAddress :this.$store.getters.userWallet},
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              this.Bate = res.data;
            }
          });
        },
      });
    },
     getInfo() {
      this.$get({
        url: "usm-offer/getOfferList",
         data:{ pageNum:this.page},
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
                this.Info = res.data;
            }
          });
        },
      });
    },
    
        getNew() {
      this.$get({
        url: "wms-wallet/getWallet",
        data:{ 'trxAddress':this.userWallet},
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              this.datas = res.data;
            }
          });
        },
      });
    },
       
  menu() {
      this.menuShow = true;
    },
       gbsmenu() {
      this.menuShow = false;
    },
     goto(val) {
      this.$router.push({ path: val });
    },
      changer(val) {
      if (val == "en-US") {
        val = "zh-CN";
      } else {
        val = "en-US";
      }
      this.$i18n.locale = val;
      this.$store.commit("language", val);
    },
    onRefresh() {
      this.page=1
      this.finished = false;
      this.loading = true;
      this.onLoad();
    },

    onLoad() {
      if (this.page == 1) {
        this.list = [];
        this.refreshing = false;
      }
      this.$get({
        url: "ums-product-history/getHistoryList",
        data: {
          pageNum: this.page,
          pageSize: 10,
          uid:this.$store.getters.myID
        },
        callback: (res) => {
          this.$nextTick(function () {
            if (res.data) {
              this.loading = false;
              if (this.page > 1) {
                this.list = this.list.concat(res.data)
              } else {
                this.list = res.data;
              }        
              if (res.data.length >= 10) {
                this.page++;
              } else {
                this.finished = true;
              }
            }
          });
        },
      });
    },

    async getlist() {
      let list = await window.ContractEth.methods
        .inviteList(this.userWallet, this.total, this.page)
        .call();
      let num = 0;
      list.forEach((el) => {
        if (el !== "0x0000000000000000000000000000000000000000") {
          num += 1;
          this.list.push({ address: el });
        }
      });
      this.total = this.total + num;
      console.log(this.list);
    },
    onCopy() {
      this.$toast(this.$i18n.t("m.fzcg"));
    },
    onError() {
      this.$toast(this.$i18n.t("m.fzsb"));
    },
      to(url) {
      location.href = url;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../src/assets/scss/_handle.scss";
$t-mf: "PingFang";
$t-f: "DINAlternate-Bold";
.main {
      position: relative;
  background:  #161628 url("../assets/img/bg50.png")  fixed no-repeat;
    // background: #0F112A;
   background-size: cover;
     background-position: top center;
  overflow-x: hidden;
  width: 750px;
  margin: 0 auto;
  min-height: 100vh;
  ::v-deep.van-nav-bar__content {
    height: 80px !important;
  }
  ::v-deep.van-nav-bar__arrow {
    font-size: 36px;
  }
  ::v-deep.van-ellipsis {
    font-size: 32px;
    line-height: 44px;
  }
  .top {
    background: transparent;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 28px;
    height: 96px;
    margin: 0 auto;
    color: #fff;
       border-bottom: 1px solid #656262;
      .back {
      position: absolute;
    left: 20px;
        width: 50px;
    height: 50px;
    // background: linear-gradient(45deg,#ffffff, #ffffff);
    // border-radius: 50% ;
    display: flex;
    align-items: center;
    justify-content: center;
      img {
        height: 50px;
        width: 50px;
      }
      .icosn {
        height: 36px;
        margin-right: 5px;
        width: 36px;
      }
    }
    .title{
        background: linear-gradient(234deg,#ffffff, #ffffff);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      font-size: 36px;
      font-weight: 600;
      font-family: $t-f;
    }

    .navbtn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 150px;
      .img {
        width: 36px;
        height: 30px;
      }
      .img2 {
        width: 36px;
        height: 36px;
        margin: 0 36px;
      }
      .lang {
        font-size: 22px;
        font-family: $t-mf;
        font-weight: 600;
        @include font_color("font_color3");
        line-height: 42px;
        // margin-left: 20px;
      }
    }
  }

   .card1 {
      width: 692px;
    // background: linear-gradient(128deg,#ffdf2d,#00e2be);
    // background: linear-gradient(128deg,#3C2F64, #CE0A54);
      // background:  url('../assets/img/tbg.png') no-repeat ;
      background: #18002de0;
      background-size: cover;
      // box-shadow: 0px 4px 16px 8px rgba(121, 216, 128, 0.3);
      // @include box_color("box_color");
      // border-radius: 20px;
      // border: 2px solid #e4e4e4;
      display: flex;
      flex-direction: row;
      margin: 50px auto 70px;
      padding: 20px 0px;
      display: flex;
      flex-direction: column;
      position: relative;
          border: 1px solid #7C58D2;
      .imgs{
        position:absolute;
        top: 30px;
        left: 30px;
        height: 88px;
        // border-radius: 100%;
        filter: drop-shadow(0 0.33333rem 0.2rem rgba(0, 0, 0, 0.5));
      }
      .title {
        font-size: 22px;
        font-family: $t-f;
        font-weight: bold;
            background: linear-gradient(90deg, #552861 0%, #C333FF 51%,  #b90f56 100%);
                 box-shadow: 0px 18px 6px rgb(16 16 16 / 39%);
    border-radius: 0px 50px 50px 0px;
    padding: 20px;
    max-width: 400px;
     min-width: 350px;
     width: auto;
        text-align: center;
        margin: 15px 48px 15px 0;
            display: flex;
    color: #ffffff;
    white-space: nowrap;
    justify-content: space-around;
      }
      .tops{
          display: flex;
          justify-content:center;
          align-items: center;
          margin: 48px;
          text-shadow: 0px 2px 15px rgba(0,0,0);
          .nft{
            width:100%;
            height:auto;
            background: #fff6f6;
            border: 1px solid  #7C58D2;
                 box-shadow: 0px 18px 6px rgb(16 16 16 / 39%);
          }
          .left{
        font-size: 20px;
        font-family: $t-f;
        font-weight: bold;
        color: #ffffff;
        line-height: 52px;
        text-align: left;
          }
          .right{
            display: flex;
            flex-direction: column;
            align-content: flex-end;
      font-size: 20px;
        font-family: $t-f;
        font-weight: bold;
        color: #ffffff;
        line-height: 52px;
         text-align: right;
          }
      }
     .vtop{
      margin: 15px 0;
      display: flex;
      justify-content: flex-end;
 .btn {
          width: 400px;
          height: 80px;
    //       background: transparent;
    //       border-radius: 0px;
    //       font-size: 32px;
    //       font-family: $t-f;
    //       font-weight: 600;
    //       color: #ffffff;
    //       line-height: 80px;
    //       border: 1px solid #ffffff;
    //           white-space: nowrap;
    // overflow: hidden;
    border:none;
     font-size: 22px;
        font-family: $t-f;
        font-weight: bold;
            background: linear-gradient(90deg, #552861 0%, #C333FF 51%,  #b90f56 100%);
                 box-shadow: 0px 18px 6px rgb(16 16 16 / 39%);
    border-radius:  50px 0 0 50px;
    padding: 20px;
    max-width: 400px;
     min-width: 350px;
        text-align: center;
        margin: 8px 0px 8px 0;
            display: flex;
    color: #ffffff;
    white-space: nowrap;
    justify-content: space-around;
        }
     }
      .bottom {
        display: flex;
        justify-content: space-between;
        margin: 0px 48px 22px;
        align-items:center;
           .txt{
              text-align: right;
                  font-size: 20px;
          font-family: $t-mf;
          font-weight: 400;
           margin-top: 10px ;
          color: #ece5e5;
            }
            .time{
              text-align: right;
                  font-size: 20px;
          font-family: $t-f;
          font-weight: bold;
          @include font_color("font_color2");
          margin: 10px 0;
           white-space: nowrap;
           color: #ece5e5;
            }
        .left{
.txt,.zccny,.zcnum,.time{
              text-align: left;}
        }
        .right{}
          
            .zcnum {
          height: 58px;
          font-size: 20px;
          font-family: $t-f;
          font-weight: bold;
         color: #ece5e5;
          line-height: 58px;
             text-align: right;
             white-space: nowrap;
        }
        .zccny {
          height: 40px;
          font-size: 20px;
          font-family: $t-f;
          font-weight: 600;
          color: #ece5e5;
          line-height: 40px;
             text-align: right;
             white-space: nowrap;
        }
        
      }
    }

  .h100{
    height: calc(100vh - 320px);
    // background: url("../assets/img/bm/b6.png") no-repeat;
    background-size: 100% auto;
    background-position: bottom ;
  }
   .mdex{
    font-size: 24px;
    color: #fff;
    background: #0a0a0ab5;
    margin: 0 0 20px;
    padding: 20px;
    border-top:1px solid  #6d6d6d;
    border-radius: 0 0 30px 30px;
    border-bottom: 1px solid #d8b819;
  }
   .van-tabs {
    ::v-deep.van-tabs__wrap {
      height: 80px;
    }
    ::v-deep.van-tab {
      line-height: 44px;
      font-size: 32px;
    }
    ::v-deep.van-tab__pane-wrapper {
      height: 100%;
    }
  }
  .mtop{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 30px;
height: 86px;
padding: 0 20px;
background: #3E2973;
border-radius: 20px;
.time{
font-size: 24px;
font-family: $t-f;
font-weight: 600;
color: #FFFFFF;
line-height: 34px;

}
.add{
  font-size: 24px;
font-family: $t-f;
font-weight: 600;
color: #FFFFFF;
line-height: 34px;
}
  }

.card2 {
      width: 694px;
      height: 100%;
      background: #fff;
      border-radius: 20px;
      margin: 20px auto;
      padding: 28px 0;
       .title {
        margin-left: 50px;
        margin-right: 28px;
        font-size: 30px;
        font-family: $t-mf;
        font-weight: 600;
        color:#000;
        line-height: 66px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icons {
          height: 36px;
          width: 36px;
        }
        .node {
          font-size: 32px;
          line-height: 40px;
        }
      }
     .lists {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 24px 0;
        .item {
          flex: 1;
          overflow: hidden;
  .num {
        margin-top: 22px;
        font-size: 42px;
        font-family: $t-f;
        font-weight: bold;
        color: #000000;
        line-height: 58px;
        white-space: nowrap;
        overflow-x: auto;
        padding: 0 20px;
        overflow-y: hidden;
      }
      .tt {
        font-size: 28px;
        font-family: $t-mf;
        font-weight: 600;
        color: #888888;
        line-height: 40px;
        margin-top: 12px;
      }
        
        }
      } 
      
    .suninfo{
      // background: #0a0a0ab5;
    padding: 10px 5px;
    margin: 10px ;
        .tls{
         margin-left: 50px;
        margin-right: 28px;
        height: 66px;
        font-size: 30px;
        font-family: $t-mf;
        font-weight: 600;
        color: #fff;
        line-height: 66px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icons{
          height: 36px;
          width: 36px;
          margin-right: 10px;
        }
        }
      .mynum{
           margin-left: 50px;
        margin-right: 28px;
        height: 66px;
        font-size: 40px;
        font-family: $t-f;
        font-weight: 600;
        color:#d8b819;
        line-height: 66px;
        text-align: left;
      }
       .bottom{
         display: flex;
         align-items: center;
         margin: 30px 0;
         .btn {
            width: 40%;
            height: 80px;
            background: #000347;
            border-radius: 10px;
            font-size: 32px;
            font-family: $t-mf;
            font-weight: 600;
            color: #ffffff;
            line-height: 80px;
            margin: 0 auto;
            text-align: center;
            border: 1px solid #000347;
            display: flex;
            align-items: center;
            justify-content: center;
            .img{
              width: 30px;
              height: 30px;
              margin-right: 10px;
            }
         }
      }
    }



      }


 .yqlink {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 18px;
      margin-left: 50px;
        margin-right: 28px;
      .ltxt {
        font-size: 30px;
        font-family: $t-mf;
        font-weight: 600;
        color: #000000;
        line-height: 42px;
      }
      .mylink {
        width: 440px;
        height: 60px;
        background: #f8f8f8;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 22px;
        .address {
          width: 250px;
          height: 32px;
          font-size: 22px;
          font-family: $t-mf;
          font-weight: 600;
          color: #000000;
          line-height: 32px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .fuzhi {
          background: transparent;
          border: none;
          width: 50px;
        }
        .icon {
          width: 42px;
          height: 42px;
        }
      }
    }
    .tips {
      font-size: 24px;
      font-family: $t-mf;
      font-weight: 400;
      color: #888888;
      line-height: 34px;
      text-align: left;
      margin: 22px 28px 0 50px;
    }



  
  .list {
    width: 696px;
    min-height: 252px;
    background: #ffffff;
    border-radius: 20px;
    margin: 0px auto;
    overflow: auto;
    .title{
      font-weight: 600;
      font-size: 28px;
      margin: 20px 32px;
      text-align: left;
    }
    .list-top {
      display: flex;
      align-items: left;
      justify-content: space-between;
      margin: 50px 0 0;
      border-top: 1px solid #f8f8f8;
      border-bottom: 1px solid #f8f8f8;
      padding: 30px 30px 30px;
      div {
        font-size: 24px;
        font-family: $t-mf;
        font-weight: 600;
        color: #8b8b93;
        line-height: 34px;
        text-align: left;
        white-space: nowrap;
        margin: 0 5px;
      }
    }
    .list-item {
      width: 100%;
      div {
        font-size: 24px;
        font-family: $t-f;
        font-weight: 400;
        color: #666666;
        line-height: 34px;
        text-align: left;
        // margin: 0 5px;
        white-space: nowrap;
        flex: 1;
        .fuzhi {
          background: transparent;
          border: none;
          width: 30px;
        }
        .fz {
          width: 28px;
          height: 26px;
          margin: 0 -5px;
        }
      }
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 24px 0px;
        border-bottom: 1px solid #f7f7fa;
        width: 100%;
      }
    }
    .d1 {
      width: 50%;
    }
    .d2 {
      width: 50%;
      text-align: right !important;
    }
  }
  
  .mentop {
    display: flex;
    justify-content: space-between;
    margin: 44px 30px;
    .logo {
      // height: 82px;
      width: 232px;
    }
    .gb {
      height: 40px;
      width: 40px;
      margin-right: 8px;
    }
  }
  .menulist {
    margin-top: 100px;
    .menuitem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 44px;
      border-bottom: 1px solid #f8f8f8;
      padding-bottom: 30px;
      .left {
        flex: 1;
        text-align: left;
        display: flex;
        img {
          height: 48px;
          width: 48px;
        }
        .txt {
          margin-left: 40px;
          font-size: 36px;
          font-family: $t-mf;
          font-weight: 600;
          @include font_color("font_color1");
          line-height: 50px;
        }
      }
      .right {
        img {
          height: 38px;
          width: 26px;
        }
      }
    }
      .act{
       .txt {
         color: #2f73fd !important;
       }
    }
  }
  
  .popup {
    width: 672px;
    background: #ffffff;
    border-radius: 30px;
    .title {
      font-size: 48px;
      font-family: $t-mf;
      font-weight: 600;
      color: #222222;
      line-height: 66px;
      margin: 44px auto;
    }

    .item {
      margin: 20px 34px;
      padding-bottom: 20px;
      border-bottom: 1px solid #eeeeee;
      .txts {
        font-size: 28px;
        font-family: $t-mf;
        font-weight: 600;
        color: #888888;
        line-height: 40px;
        text-align: center;
        margin: 10px 0;
      }
      .txd{
        margin: 20px 0;
        font-size: 34px;
        color: #30b74b;
        text-align: center;
      }
     
    }
     .btns {
      width: 480px;
      height: 80px;
      font-size: 32px;
      font-family: $t-mf;
      font-weight: 600;
      color: #ffffff;
      line-height: 80px;
      margin: 54px auto;
background: #b4184c;
box-shadow: 0px 4px 16px 4px rgba(207,182,247,1);
border-radius: 40px;
border: none;
  display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .buttoms {
      height: 28px;
      font-size: 24px;
      font-family: $t-f;
      font-weight: bold;
      color: #999999;
      line-height: 28px;
      margin: 80px auto 0;
      padding-bottom: 80px;
    }
    .tips{
      font-size: 24px;
font-family: $t-f;
font-weight: 600;
color: #8D899F;
line-height: 34px;
margin-top: 42px;
    }
     .noData{
		width: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		img{
			width: 242px;
			margin: 150px auto 20px;
		}
     .ttx{
      font-size:32px;
      color: #ffffff !important;
  font-family: $t-f;
   font-weight: bold;
    }
	}
}
</style>